.navbar {
    z-index: 5000;
    position: fixed;
    min-height: 50px;
    max-height: 50px;
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 750px) {
    .navbar {
        background-color: rgb(27, 27, 27);
    }
  }

.navbar-left {
    min-height: 50px;
    max-height: 50px;
    min-width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-right {
    min-height: 50px;
    max-height: 50px;
    min-width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.personal-logo {
    margin-left: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 80%;
    transition: opacity .2s ease-in, top .2s ease;
}

.personal-logo:hover {
    opacity: 100%;
}

.personal-logo > img {
    max-width: 45px;
}

.nav-button {
    background-color: rgb(129, 178, 154, 0);
    transition: opacity .2s ease-in, top .2s ease;
    color: white;
    opacity: 80%;
    font-size: 10px;
    font-weight: 600;
    border: none;
    margin-right: 15px;
    cursor: pointer;
    
}

.button-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
}

.button-logo > img {
    max-width: 30px;
    min-width: 30px;
}

.nav-button:hover {
    opacity: 100%;    
}

.nav-button:active {
    position: relative;
    top: 1px;
}