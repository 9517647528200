.video-projects {
  height: 100%;
  // min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #0f2d30;
}

.video-project {
  // height: 100vh;
  width: 100%;
}

.video-production-title {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.video-projects-header {
  position: absolute;
  z-index: 15;
  min-width: 100%;
  min-height: 80%;
  margin-top: -300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  animation: headerSlideOut 10s ease-in;
}

@keyframes headerSlideOut {
  0% {
    margin-top: 0px;
  }
  90% {
    margin-top: 0px;
  }
  96% {
    margin-top: -5px;
  }
  100% {
    margin-top: -300px;
  }
}

.video-projects-header > h1 {
  font-size: 60px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.025em;
  padding: 10px;
  margin-bottom: -100px;
  color: #0f2d30;
  cursor: default;
}

.video-controller {
  z-index: 28;
  position: absolute;
  min-width: 250px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  animation: slideBarIn 1s ease;
}

@keyframes slideBarIn {
  0% {
    transform: translate(500px, 0px);
  }
  50% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.video-button {
  margin-top: 35px;
  margin-bottom: 35px;
  min-height: 55px;
  max-height: 55px;
  min-width: 150px;
  max-width: 190px;
  // background-color: #ffddd1;
  color: rgb(43, 35, 35);
  // background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-button > img {
  max-width: 200px;
}

.trans-bar {
  position: absolute;
  min-width: 500px;
  min-height: 300vh;
  margin-right: -235px;
  background-color: black;
  opacity: 35%;
  box-shadow: -10px 0px 20px rgb(43, 35, 35, 0.3);
}

.mute-button {
  margin-top: 15px;
  min-height: 55px;
  max-height: 55px;
  min-width: 200px;
  max-width: 200px;
  // background-color: #ffddd1;
  color: rgb(43, 35, 35);
  // background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-controller > button {
  z-index: 20;
  background-color: rgb(129, 178, 154, 0);
  transition: opacity 0.2s ease-in, top 0.2s ease;
  color: white;
  opacity: 80%;
  font-size: 10px;
  font-weight: 600;
  border: none;
  margin-right: 15px;
  cursor: pointer;
  transition: opacity 0.2s ease;
}

.video-controller > button:hover {
  opacity: 100%;
}

.video-controller > button:active {
  transform: scale(97.5%);
}

.open-side-bar-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  animation: slidingText 2s ease infinite;
}

.open-side-bar {
  z-index: 25;
  transform: rotate(270deg);
  cursor: pointer;
  color: white;
  opacity: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.open-side-bar:hover {
  opacity: 100%;
}

@keyframes slidingText {
  0% {
    margin-right: -50px;
  }
  50% {
    margin-right: -45px;
  }
  100% {
    margin-right: -50px;
  }
}

.open-side-bar > h5 {
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 3px;
}

.close-side-bar {
  position: absolute;
  left: -95px;
  min-width: 100px;
  color: white;
  opacity: 80%;
  cursor: pointer;
  transition: opacity 0.2s ease;
  flex-direction: column;
  transform: rotate(270deg);
  background-color: rgb(43, 35, 35, 0.8);
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
}

.close-side-bar {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 3px;
}

.close-side-bar:hover {
  opacity: 100%;
}

.fa-caret-up {
  position: relative;
  top: 1px;
  margin-left: 15px;
  margin-right: 15px;
}

#caret-one {
  top: -10px;
}

.transition-container {
  z-index: 30;
  position: absolute;
  min-width: 100%;
  min-height: 155px;
  max-height: 155px;
  display: flex;
  align-items: flex-start;
}
.transition-layer {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.hobbies-transition {
  z-index: 30;
  position: absolute;
  min-width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  top: 91%;
}

.pipes-pipes {
  z-index: 900;
}

@media (max-width: 1200px){
  .trans-bar {
    min-width: 455px;
    max-width: 455px;
  }

  .close-side-bar {
    left: -50px;
  }

  .video-controller {
    margin-right: 0px;
    min-height: 500px;
    max-height: 500px;
    max-width: 200px;
  }

  .video-button > img {
    min-width: 150px;
    max-width: 120px;
  }

  .video-button {
    margin-top: 13px;
    margin-bottom: 13px;
    transform: translate(-8px, 0px);
  }

}

@media (max-width: 850px){

  .trans-bar {
    min-width: 420px;
    max-width: 420px;
  }

  .close-side-bar {
    left: -15px;
  }

  .video-controller {
    margin-right: 0px;
    min-height: 500px;
    max-height: 500px;
  }

  .video-button > img {
    min-width: 125px;
    max-width: 125px;
  }

  .video-button {
    margin-top: 3px;
    margin-bottom: 3px;
    transform: translate(2px, 0px);
  }

  .mute-button {
    transform: translate(25px, 0px);
  }

}