.mobile-container {
  position: absolute;
  min-width: 100%;
  min-height: 100vh;
  background-color: rgb(27, 27, 27);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Share Tech Mono", monospace;
  font-size: 11px;
  cursor: default;
  margin-top: 50px;
}

.mobile-contents {
  margin-top: 70px;
  margin-bottom: 50px;
}

#runs-best {
  color: rgb(27, 27, 27);
  max-width: 175px;
  min-width: 175px;
  max-height: 50px;
  padding: 10px;
  margin-top: -60px;
  margin-bottom: 20px;
  position: relative;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  animation: noticeSlideIn 2s ease-out, noticeFadeIn 2s ease-out;
  transition: background-color 0.4s ease, color 0.4s ease;
  opacity: 85%;
}

#runs-best:hover {
  background-color: rgb(48, 48, 48);
  color: white;
}

@keyframes noticeSlideIn {
  0% {
    transform: translate(-50px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes noticeFadeIn {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 85%;
  }
}

#sixteennine {
  font-weight: 600;
  max-width: 150px;
}

.mobile-item {
  margin-left: 75px;
  margin-right: 75px;
}

.mobile-links-container {
  margin-top: 15px;
}

.mobile-link {
  text-decoration: none;
  color: white;
  margin-right: 15px;
  transition: color 0.3s ease;
  font-weight: 600;
  line-height: 13px;
}

.mobile-link:hover {
  color: #ff9b77;
}

.mobile-item > h6 {
  margin-bottom: 4px;
}

.mobile-item > h1 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .mobile-item > h1 {
    margin-top: 0px;
  }
}

.mobile-item > h2 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 5px;
}

#phony-mobile {
  margin-top: 15px;
}

.mobile-item > h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 15px;
}

.mobile-item > h4 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 30px;
}

.mobile-item > h5 {
  font-size: 13px;
  font-weight: 400;
}

.close-mobile-button {
  position: absolute;
  max-width: 175px;
  min-width: 175px;
  min-height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 15px;
}

.fa-circle-xmark {
  cursor: pointer;
}

.fa-circle-xmark:hover {
  color: #ff9b77;
  transition: color 0.3s ease;
}
