html {
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  display: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.App {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "DM Sans", sans-serif;
}

.main-1 {
  min-height: 100vh;
  min-width: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin: none;
}

.main-1-5 {
  z-index: 3000;
  min-height: 590px;
  min-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: none;
  padding-top: 100px;
  background-color: #0f2d30;
}

.main-2 {
  display: block;
  position: relative;
  z-index: 12;
  min-height: 110vh;
  min-width: 100%;
  max-width: 100%;
  background-color: #0f2d30;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: none;
  color: #ffddd1;
}

.main-3 {
  display: block;
  position: relative;
  z-index: 13;
  min-width: 100%;
  max-width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 110vh;
  color: #0f2d30;
  object-fit: fill;
}

.main-4 {
  z-index: 30;
  min-height: 100vh;
  min-width: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  margin: none;
}

@media (max-width: 750px) {
  .App {
    display: none;
  }
}